<template>
  <v-card
    light
    max-width="100%"
    width="400"
    :loading="loading ? 'white' : false"
  >
    <v-card-title
      class="elevation-3 white--text pa-5 justify-center"
      :style="{background: $vuetify.theme.themes.light.red}"
    >
      <h1 class="text-h4 font-weight-bold mb-2 text-center">
        {{ $t('messages.views.auth.resetPassword.title') }}
      </h1>
    </v-card-title>

    <o-form
      v-if="valid === true"
      @submit="submit"
    >
      <v-card-text
        class="text-center pa-5"
      >
        <p class="text-center">
          {{ $t('messages.views.auth.resetPassword.description') }}
        </p>

        <o-text-field
          v-model="password"
          type="password"
          :rules="validation.password"
          label="labels.user.password"
          prepend-icon="mdi-lock-outline"
        />

        <o-text-field
          v-model="passwordConfirm"
          type="password"
          :rules="{
            ...validation.password,
            password: password,
          }"
          label="messages.views.auth.resetPassword.confirmPassword"
          prepend-icon="mdi-lock-outline"
        />
      </v-card-text>

      <v-card-actions
        class="pa-5"
      >
        <v-btn
          type="submit"
          large
          dark
          color="red"
          class="v-btn--text flex-grow-1"
        >
          {{ $t('actions.validate') }}
        </v-btn>
      </v-card-actions>
    </o-form>

    <v-card-text
      v-else-if="valid === false"
      class="text-center pa-5"
    >
      <p>
        {{ $t('messages.views.auth.resetPassword.linkExpired') }}
      </p>

      <router-link
        :to="{ name: 'auth_password_forgotten' }"
        class="grey--text text-decoration-none"
      >
        <span>
          <v-icon
            small
          >
            mdi-lock
          </v-icon>
          {{ $t('messages.views.auth.resetPassword.askNewPassword') }}
        </span>
      </router-link>
    </v-card-text>

    <v-card-text
      v-else
    >
      <v-skeleton-loader
        class="mx-auto mt-5"
        max-width="300"
        type="card"
      />
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    data: () => ({
      validation: require('@validation/entities/user.json'),
      password: '',
      passwordConfirm: '',
      valid: null,
      loading: false,
    }),

    mounted () {
      this.$apollo.query({
        query: require('@gql/queries/user/isTokenValid.gql'),
        client: 'api-gateway',
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        result (res, key) {
          this.loading = res.loading;
        },
        variables: {
          type: 'TYPE_RESET_PASSWORD',
          value: this.$route.params.token,
        },
      }).then(result => {
        this.valid = result.data.isTokenValid;
      });
    },

    methods: {
      submit () {
        this.loading = true;
        this.$apollo.mutate({
          mutation: require('@gql/mutations/user/setUserPassword.gql'),
          client: 'api-gateway',
          variables: {
            input: {
              token: this.$route.params.token,
              password: this.password,
            },
          },
        }).then((result) => {
          const user = result.data.setUserPassword;

          this.$store.commit('auth/setToken', user.token);
          this.$store.commit('auth/login', {
            id: user.id,
            email: user.email,
            roles: user.roles,
          });
        }).catch((e) => {
          if (e.graphQLErrors !== undefined && e.graphQLErrors[0] !== undefined) {
            switch (e.graphQLErrors[0].extensions.code) {
              case 'TOKEN_EXPIRED':
                this.$flash('messages.views.auth.resetPassword.errors.tokenExpired', 'error');
                break;
              case 'INSUFFICIENT_USER_LICENSES':
                this.$flash('messages.views.auth.resetPassword.errors.insufficientUserLicenses', 'error');
                break;
              default:
                this.$flash('messages.views.auth.resetPassword.errors.default', 'error');
            }
          }
        }).finally(() => {
          this.loading = false;
        });
      },
    },
  };
</script>
